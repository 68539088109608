import './App.css';
import {useState, useEffect, useCallback} from "react";
import {ethers} from "ethers"
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import WalletConnectProvider from "@walletconnect/web3-provider"
import Web3Modal from "web3modal"
import Logo from './assets/logo512.png'
import pepegif from './assets/ukiyopepe.gif'
import egirlgif from './assets/ukiyo-egirls.gif'
import Lottie from 'react-lottie'
import NetworkLottie  from './assets/connection-failed.json'
import gasImg from './assets/gasicon.png'
import blockImg from './assets/blockicon.png'
import UkiyoPepe from './UkiyoPepe.json'
import eGirl from './UkiyoeGirl.json'
import eGirlBG from './assets/egirlbg.png'
import sadge from './assets/sadge.png'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

function App() {
  const [provider, setProvider] =useState(false);
  const [network, setNetwork] = useState();
  const [account, setAccount] = useState();
  const [address, setAddress] = useState(); 
  const [avatar, setAvatar] = useState();
  const [balance, setBalance] = useState();
  const [gasPrice, setGasPrice] = useState();
  const [txHash, setTxHash] = useState();
  const [blockNumber, setBlockNumber] = useState();
  const [showPepes, setShowPepes] = useState();
  const [showEgirls, setShowEgirls] = useState();
  const [isWhiteListed, setIsWhiteListed] = useState();
  const bgvideo = 'https://tofushop.mypinata.cloud/ipfs/QmP4tPjoTT8yYtWkxjEurffBt5MkVMER5GddKHV3bGAozX';
  const etherLink = `https://etherscan.io/address/${address}`;
  const [mintAmount, setMintAmount] = useState(1);
  const [totalMinted, setTotalMinted] = useState();
  const UKIYOPEPE_CONTRACT_ADDRESS = "0x260107107FBd99c51fa8C0747C67Bdce71351BCE"
  const EGIRL_CONTRACT_ADDRESS = "0x2c4A0c62E34F2B5C91A90b85be3798C81d9a8c83"
  const ukiyoPepeContractLink =`https://etherscan.io/address/0x260107107fbd99c51fa8c0747c67bdce71351bce`
  const ukiyoeGirlContractLink ='https://etherscan.io/address/0x2c4A0c62E34F2B5C91A90b85be3798C81d9a8c83'
  const [pepeBalance, setPepeBalance] = useState();
  const [eGirlBalance, setEgirlBalance] = useState();
  const { Network, Alchemy } = require("alchemy-sdk");

  const etherscanTx = `https://etherscan.io/tx/${txHash}`;
  // const [imageUrls, setImageUrls] = useState();

  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_KEY, // Replace with your Alchemy API Key.
    network: Network.ETH_MAINNET, // Replace with your network.
  };
  
  const alchemy = new Alchemy(settings);

  async function block() {
    const latestBlock = await alchemy.core.getBlockNumber();
    console.log("The latest block number is", latestBlock);
  }

  // block();

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
          newMintAmount = 1;
        }
        setMintAmount(newMintAmount);

      };
    
      const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 20) {
          newMintAmount = 20;
        }
        setMintAmount(newMintAmount);
      };

      const maxMintAmount = () => {
        let newMintAmount = 20;
        setMintAmount(newMintAmount);
      };

    const LottieOptions = {
        loop: true,
        autoplay: true,
        animationData: NetworkLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
  
    async function getWeb3Modal() {
      const web3modal = new Web3Modal({
          network: 'Mainnet',
          cacheProvider: false,
          providerOptions: {
              walletconnect: {
                  package: WalletConnectProvider,
                  options: { 
                      infuraID: process.env.REACT_APP_INFURA_ID,
                      chainId: 1,
                      darkMode: true
                  },
              },
              coinbasewallet: {
                  package: CoinbaseWalletSDK, // Required
                  options: {
                      appName: "Ukiyo-eGAN", // Required
                      infuraID:  process.env.REACT_APP_INFURA_ID, // Required
                      chainId: 1, 
                      darkMode: true
                  }
              }
          },
      })
      return web3modal
  }

    async function connect() {
      try {
          const web3Modal = await getWeb3Modal()
          const connection = await web3Modal.connect()
          const provider = new ethers.providers.Web3Provider(connection)
          setProvider(provider)
          const _network = await provider.getNetwork();
          const _id = _network.chainId.toString();
          console.log("network: ", _network)
          setNetwork(_id);
          const accounts = await provider.listAccounts()
          setAddress(accounts[0]);
          }  catch (err) {
          console.error(err);
          console.log(address);
        }
    }

    const fetchNetwork = useCallback(async () => {
      try {
        if (provider) { 
            const _provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            _provider.on("network", (newNetwork, oldNetwork) => {
                // When a Provider makes its initial connection, it emits a "network"
                // event with a null oldNetwork along with the newNetwork. So, if the
                // oldNetwork exists, it represents a changing network
                if (oldNetwork) {
                    window.location.reload();
                }
            },);
        }
      } catch (error) {
        console.error(error)
      }
    }, [provider]);

    async function switchNetwork() {
        window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{
              chainId: "0x1"
          }]
      }).then( () => {
          window.location.reload();

      }).catch(error => {
              console.error(error);
      })
    } 

    useEffect(() => {

        async function getBalance(){
          if (address && provider) {
            try {
              const accountBalance = await provider.getBalance(address)
              const data = ethers.utils.formatEther(accountBalance).toString()
              setBalance(parseFloat(data.toString()).toFixed(8))
            } catch(err) {
              console.error(err);
            } 
          } 
        }

      async function resolveENS() {
        if(address) {
          try {
            const ensName = await provider.lookupAddress(address);
            if(ensName) {
              setAccount(ensName)
              const resolver = await provider.getResolver(ensName);
              const avatar = await resolver.getAvatar();
              if(avatar) {
                setAvatar(avatar.url)
              }
            }
            else {
              setAccount(address.substring(0, 6) + "..." + address.substring(36));
            }

          } catch(err) {
            console.log(address)
          }
        }
      }

      async function getGasPrice(){
        if (provider) {
          try {
            const gasPrice = await provider.getGasPrice()
            const data = ethers.utils.formatUnits(gasPrice, 'gwei').toString()
            setGasPrice(parseFloat(data.toString()).toFixed(2))
          } catch(err) {
            console.error(err);
          }
        }
      }


      async function getBlockNumber() {
        if(provider && network == 1)
          try{
            const blockNumber = await provider.getBlockNumber();
            setBlockNumber(blockNumber);
          } catch (e) {
            console.log(e)
          }
      }

      // async function getMaxSupply() {
      //   if(provider && network == 5)
      //   try {
      //       const contract = new ethers.Contract(EGIRL_CONTRACT_ADDRESS, UkiyoPepe, provider);
      //       const maxSupply = await contract.TOTAL_SUPPLY();
      //       setMaxSupply(maxSupply.toNumber())

      //   } catch (err) {
      //       console.error(err)
      //   }
      // }

      async function totalSupply() {
        if(provider && network == 1)
        try {
            const contract = new ethers.Contract(EGIRL_CONTRACT_ADDRESS, UkiyoPepe, provider);
            const supply = await contract.totalSupply();
            setTotalMinted(supply.toNumber())

        } catch (err) {
            console.error(err)
        }
    }  
     
  
      resolveENS();
      fetchNetwork();
      totalSupply();
    const interval = setInterval(() => {
      getGasPrice();
      getBalance();
      getBlockNumber();
    }, 500);
    return () => clearInterval(interval);

  },[account, address, provider, gasPrice, balance, blockNumber, fetchNetwork, network, totalMinted])

  // console.log(address)

    async function checkPepeBalance() {
        try {
          const contract = new ethers.Contract(UKIYOPEPE_CONTRACT_ADDRESS, UkiyoPepe, provider);
          const signer = provider.getSigner();
          const contractSigner = contract.connect(signer);

          const pepeBalance = await contractSigner.balanceOf(signer.getAddress());
          // const newPepeBalance = pepeBalance.toNumber() + mintAmount;
          setPepeBalance(pepeBalance.toNumber());
          if (pepeBalance > 0) {
            // setShowPepes(true);
            setIsWhiteListed(true);
            return;
          }

          // setShowPepes(false);
          setIsWhiteListed(false);
        } catch (error) {
          console.error(error);
        }
      }

      checkPepeBalance();

      async function checkEgirlBalance() {
        try {
          const contract = new ethers.Contract(EGIRL_CONTRACT_ADDRESS, eGirl, provider);
          const signer = provider.getSigner();
          const contractSigner = contract.connect(signer);

          const eGirlBalance = await contractSigner.balanceOf(signer.getAddress());
          // const newPepeBalance = pepeBalance.toNumber() + mintAmount;
          setEgirlBalance(eGirlBalance.toNumber());

        } catch (error) {
          console.error(error);
        }
      }

      checkEgirlBalance();

      async function getOwnedPepesFromContract() {
        const options = {
          contractAddresses: [UKIYOPEPE_CONTRACT_ADDRESS]
        };
        if (provider) {
          let gatewayUrls = [];
          for await (const nft of alchemy.nft.getNftsForOwnerIterator(address, options)) {
            for(let i=0; i < nft.media.length; i++){
              gatewayUrls.push(nft.media[i].gateway);
            }
          }
          console.log(gatewayUrls);
          let container = document.getElementById("image-container");
          for (let i = 0; i < gatewayUrls.length; i++) {
              let img = document.createElement("img");
              img.src = gatewayUrls[i];
              img.classList.add("UkiyoPepeImage");
              container.appendChild(img);
              // Add the click event listener to each image
              img.addEventListener("click", function() {
                window.open(gatewayUrls[i], '_blank');
              });
          
          }
        }
      }

      async function getOwnedEgirlsFromContract() {
        const options = {
          contractAddresses: [EGIRL_CONTRACT_ADDRESS]
        };
        if (provider) {
          let gatewayUrls = [];
          for await (const nft of alchemy.nft.getNftsForOwnerIterator(address, options)) {
            for(let i=0; i < nft.media.length; i++){
              gatewayUrls.push(nft.media[i].gateway);
            }
          }
          console.log(gatewayUrls);
          let container = document.getElementById("eGirl-image-container");
          for (let i = 0; i < gatewayUrls.length; i++) {
              let img = document.createElement("img");
              img.src = gatewayUrls[i];
              img.classList.add("UkiyoeGirlImage");
              container.appendChild(img);
              // Add the click event listener to each image
              img.addEventListener("click", function() {
                window.open(gatewayUrls[i], '_blank');
              });
          
          }
        }
      }
      
      const holderEgirlMint = async event => {
        try{
            const signer = provider.getSigner();
            const contract = new ethers.Contract(EGIRL_CONTRACT_ADDRESS, eGirl, signer);

            const estimation = await contract.estimateGas.ukiyoPepeHolderMint(mintAmount);
            console.log("estimation: ", estimation.toString());
            console.log("bigEstimation: ", estimation.mul(5).div(4).toString());

            const mintAmountString = mintAmount.toString();
            console.log(mintAmountString);
      
            const tx = await contract.ukiyoPepeHolderMint( mintAmount, {gasLimit: estimation.mul(5).div(4)} );



            console.log("Mining... ", tx.hash);
            setTxHash(tx.hash);
            await tx.wait();
            console.log("Mined --");

        } catch(error) {
            console.error(error)
        }
    }      

    
    const publicEgirlMint = async event => {
        try{
            const signer = provider.getSigner();
            const contract = new ethers.Contract(EGIRL_CONTRACT_ADDRESS, eGirl, signer);

            const estimation = await contract.estimateGas.publicMint(mintAmount);
            console.log("estimation: ", estimation.toString());
            console.log("bigEstimation: ", estimation.mul(5).div(4).toString());

            const mintAmountString = mintAmount.toString();
            console.log(mintAmountString);
      
            const tx = await contract.publicMint( mintAmount, {gasLimit: estimation.mul(5).div(4)} );



            console.log("Mining... ", tx.hash);
            setTxHash(tx.hash);
            await tx.wait();
            console.log("Mined --");

        } catch(error) {
            console.error( error )
        }
    }

    // const showUkiyoeGirl = async () => {
    //   await checkEgirlBalance();
    //   await getOwnedEgirlsFromContract();
    // };

    const showUkiyoPepe = async () => {
        setShowPepes(true);
        await getOwnedPepesFromContract();
    };

    const showMyEgirls = async () => {
      setShowEgirls(true);
      await getOwnedEgirlsFromContract();
    };

    // window.onload = function() {
    //   getOwnedTokensFromContract();
    // };
  

return (
    <div className="App">
      <div className="networkpopup">

            {network != 1 && provider && (
            <div className='networkpopup'>
              <h4>
                Wrong Network
              </h4>
              <Lottie options={LottieOptions}
                height={200}
                width={200}
              />
              <p>
                You are currently on chainID {network} < br/>
                please change to mainnet 
              </p>
              <button className="networkButton" onClick = {switchNetwork}>
                Switch Network
              </button>
            </div>
            )}
            
              {!account && ( 

                        <div className='connect'>
                        <img className='getavi2'
                                src={egirlgif}
                                alt="pls set ens avatar"
                              />
                            <button className="connectButton" onClick={connect}>connect wallet</button>
                        </div>
            )}


      {network == 1 && account && (

          <div className="main">
            <div className='avi-wrapper'>
              <a href={etherLink} target='blank'>
              {/* {avatar ?
                <img className='getavi'
                  src={avatar}
                  alt="avatar"
                />
                :
                <img className='getavi'
                  src={pepegif}
                  alt="pls set ens avatar"
                />
            } */}    
              <img className='getavi'
                  src={egirlgif}
                  alt="pls set ens avatar"
                />
              </a>
          <div className='account-details'>   
              <a href={etherLink} className='accountText' target='blank'>
                {account} {balance}Ξ 
              </a> 
            <div className='gwei'>
                {gasPrice} GWEI 
                <img className="gasImg"
                src={gasImg}/>
          </div>
            <div className='block'>
                  {blockNumber > 0 ? `${blockNumber}` : "" }
                  <img className="gasImg"
                src={blockImg}/>
              </div>
            </div>
            </div>


            <div className='mintAmount'>
              <p>{totalMinted} of 1000 unique Ukiyo-eGirls already minted for free.</p>
            </div>
              <a href='https://www.nftembed.org/embed/collection/?hasUserProfile=true&tokenAddress=0x2c4A0c62E34F2B5C91A90b85be3798C81d9a8c83&showMarket=true&saleSplitAddress=0x5473580406D12E1cBD4c00B77e158FfF0CE9424e&saleSplitFee=10' className='contractText2'>UkiyoeGirls are sold out, buy some on NFT Embed</a>
            
            <div className="showNFTButtons">

              <button className="nftButton" onClick={showUkiyoPepe}>
                  my UkiyoPepe
                </button>
              <button className="nftButton" onClick={showMyEgirls}>
                  my eGirls
                </button>

            </div>
              
            <div className='contractLinks'>
            <a href={ukiyoPepeContractLink} className='contractText' target='blank'>
              UkiyoPepe Contract </a>
            <a href={ukiyoeGirlContractLink} className='contractText' target='blank'>
              Ukiyo-eGirl Contract </a>
            </div>
          </div>

      )}
      {showPepes && (
              <div className='pepeHolder'>
                <h4 className=''>Greedy Frog...</h4>
                {avatar ?
                <img className='getavi2'
                  src={avatar}
                  alt="avatar"
                />
                :
                <img className='getavi2'
                  src={Logo}
                  alt="pls set ens avatar"
                />
            }
                <p className='sorryText'>You have {pepeBalance} UkiyoPepe's. They are sold out <img className='sadge' src={sadge}/> but you can buy some on NFT Embed.</p>
                <div className='horizontalButtons'>
                    <button className="networkButton" onClick={() => window.open('https://www.nftembed.org/embed/collection/?hasUserProfile=true&tokenAddress=0x260107107FBd99c51fa8C0747C67Bdce71351BCE&showMarket=true&saleSplitAddress=0x5473580406D12E1cBD4c00B77e158FfF0CE9424e&saleSplitFee=10', '_blank')}>Market</button>
                    <button className="networkButton" onClick={() => window.open('https://foundnone.xyz/discord')}> Discord </button>
                    <button className="networkButton" onClick={() => setShowPepes(false)}> Close </button>
                </div>
                <div className='ownedPepes'>
                  <h4>Your UkiyoPepes</h4>
                    <div className="image-container" id="image-container"></div>
                </div>
                </div>
            )}

            {showEgirls && (
              <div className='pepeHolder'>
                <h4 className=''>SUGOI!!!</h4>
                {avatar ?
                <img className='getavi2'
                  src={avatar}
                  alt="avatar"
                />
                :
                <img className='getavi2'
                  src={egirlgif}
                  alt="pls set ens avatar"
                />
            }
                <p className='sorryText'>You have {eGirlBalance} UkiyoeGirl's.</p>
                <div className='horizontalButtons'>
                    <button className="networkButton" onClick={() => window.open('https://www.nftembed.org/embed/collection/?hasUserProfile=true&tokenAddress=0x2c4A0c62E34F2B5C91A90b85be3798C81d9a8c83&showMarket=true&saleSplitAddress=0x5473580406D12E1cBD4c00B77e158FfF0CE9424e&saleSplitFee=10', '_blank')}>Market</button>
                    <button className="networkButton" onClick={() => window.open('https://foundnone.xyz/discord')}> Discord </button>
                    <button className="networkButton" onClick={() => setShowEgirls(false)}> Close </button>
                </div>
                <div className='ownedPepes'>
                  <h4>Your Ukiyo eGirls</h4>
                    <div className="eGirl-image-container" id="eGirl-image-container"></div>
                </div>
                </div>
            )}


            {/* <video className="bgvideo"
                autoPlay
                loop
                muted
                src={bgvideo}/> */}

            <img className="bgimg" src={eGirlBG} alt="bgimg" />


            </div>

            </div>

        );
      }

export default App;